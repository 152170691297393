import React, { Component } from 'react';
import './style.css';

export default class ServerSelector extends Component {
    render() {
        const { location: { pathname }} = window;
        const { server } = this.props;

        if (pathname === '/' || !server) {
            return (<span>&nbsp;</span>);
        }

        return (
            <div className='region-display' onClick={e => window.location = '/'}>
                {server.name}
            </div>
        );
    }
}
