import React from 'react';
import { Line, LineChart, Label, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip} from 'recharts';
import '../../style.css';


export const ReportLineGraph = ({ title, subTitle, xLabel, yLabel, data, dataKeys, nameKey, interval, colors, toolTip, labels }) => {

  const legendLabels = labels || dataKeys;

  return(
    <div className="graph" style={{ marginTop: 16 }}>
      <h1>{title}</h1>
      <h2>{subTitle}</h2>
      <ResponsiveContainer
        width="100%"
        height={300}>
        <LineChart
          data={data}
          margin={{ top: 15, right: 30, left: 20, bottom: 5 }}>
          <XAxis
            dataKey={nameKey}
            type="category"
            interval={interval}
            tick={{ fontSize: 12, fill: 'white' }}>
            <Label
              value={xLabel}
              offset={0}
              position="insideBottom"
              style={{ textAnchor: 'middle', fontSize: '0.85rem', fill: 'rgba(255, 255, 255, 0.87)' }}
            />
          </XAxis>
          <YAxis
            dataKey={dataKeys[0]}
            name='Value'
            allowDecimals={false}
            tick={{ fontSize: 12, fill: 'white' }}
          >
            <Label
              value={yLabel}
              angle={-90}
              offset={0}
              position="insideLeft"
              style={{ textAnchor: 'middle', fontSize: '0.85rem', fill: 'rgba(255, 255, 255, 0.87)' }}
            />
          </YAxis>

          <Legend />
          <Tooltip
            content={toolTip}/>
          {
            dataKeys.map((entry, index) => <Line dataKey={entry} name={legendLabels[index]} key={`line-${index}`}  stackId={`stack-${index}`} strokeWidth={3} stroke={colors[index % colors.length]} fill={colors[index % colors.length]} barSize={10} />)
          }
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};


