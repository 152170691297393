import React from 'react';
import { Bar, BarChart, Label, ResponsiveContainer, XAxis, YAxis, Tooltip} from 'recharts';
import '../../style.css';


export const ReportBarGraph = ({ title, subTitle, xLabel, yLabel, data, dataKey, nameKey, interval, colors, toolTip }) => {
  return(
    <div className="graph" style={{ marginTop: 16 }}>
      <h1>{title}</h1>
      <h2>{subTitle}</h2>
      <ResponsiveContainer
        width="100%"
        height={300}>
        <BarChart
          data={data}
          margin={{ top: 15, right: 30, left: 20, bottom: 5 }}>
          <XAxis
            dataKey={nameKey}
            type="category"
            interval={interval}
            tick={{ fontSize: 12, fill: 'white' }}>
            <Label
              value={xLabel}
              offset={-2.5}
              position="insideBottom"
              style={{ textAnchor: 'middle', fontSize: '0.85rem', fill: 'rgba(255, 255, 255, 0.87)' }}
            />
          </XAxis>
          <YAxis
            dataKey={dataKey}
            name='Value'
            allowDecimals={false}
            tick={{ fontSize: 12, fill: 'white' }}
          >
            <Label
              value={yLabel}
              angle={-90}
              offset={0}
              position="insideLeft"
              style={{ textAnchor: 'middle', fontSize: '0.85rem', fill: 'rgba(255, 255, 255, 0.87)' }}
            />
          </YAxis>

          <Tooltip
            content={toolTip}/>
          <Bar dataKey="value" stackId="a" fill={colors[0]} barSize={10} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};


