import React, { Component } from 'react';
import { Dot } from 'react-animated-dots';
import { CSVDownload } from 'react-csv';
import axios from 'axios';
import {
    GET_CSV_MAU_SESSIONS_DATA
} from '../../config/api';
import { Auth } from 'aws-amplify';
import _ from 'lodash';
import './style.css';
import playrLogo from '../../images/playr-log.png';

export default class MauSessionsCsv extends Component {
  _isMounted = false;

  state = {
    statusCode: 200,
    csvMauSessionsData: null,
    errors: []
  };

  /***
   * Checks to see if the user belongs to the correct group.
   * Ignores this check if in localhost, again calls on backend are secured
   *
   * @returns {boolean|*}
   */
  checkAccess(){
    const { location:{ hostname }} = window;
    if (hostname === "local.pteksys.com" ||
        hostname === "localhost" ||
        hostname === "127.0.0.1" ||
        hostname === "") {
      return true;
    }

    const { user } = this.props;
    const groups = user.signInUserSession.idToken.payload['cognito:groups']
        .map(group => group.toLowerCase());

    return groups.includes('admin') || groups.includes('management');
  }

  componentDidMount() {
    this._isMounted = true;
    if(this.checkAccess()){
      this.loadData();

      this.onResize = this.onResize.bind(this);
      window.addEventListener("resize", this.onResize);
      this.handleSizeChange();
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
    window.removeEventListener("resize", this.onResize)
  }

  onResize(){
    this.handleSizeChange();
  }

  handleSizeChange = _.debounce( () => {}, 500);

  loadRemoteData = async (url, updateStateFn) => {
    try {
      const result = await axios.get(url)
      const { status, data } = result;
      if (status === 200) {
          return updateStateFn(data);
      }
    }
    catch (error) {
      console.log(error);
    };
  }

  loadData = async () => {

    const result = await this.loadRemoteData(GET_CSV_MAU_SESSIONS_DATA, (data) => data);

    const headers = [
        { label: "Date", key: "date" },
        { label: "MAU Users", key: "numUsers" },
        { label: "Sessions (total uploads)", key: "uploads" },
        { label: "Sessions per MAU", key: "sessions_per_mau" },
      ];

    this.setState({
        csvMauSessionsData: {
            data: result,
            headers: headers
        }
    })
  };

  signOut = () => {
    Auth
      .signOut()
      .then( () => {
        console.log('Sign out was successful');
    })
      .catch( err =>{
        console.log(err);
      });
  };

  convertObjToGraphArray(obj){
    if(obj === null || obj === undefined) return obj;

    return Object.keys(obj).map(function(key) {
      return { name: key, value: obj[key]};
    });
  }

  render() {
    const {
        csvMauSessionsData
    } = this.state;

    // console.log('Mau Data: ' + JSON.stringify(csvMauSessionsData));

    const { user } = this.props ;
    if(this.checkAccess() === false){
      return (

        <main>
          <nav>
            <div className="welcome">Welcome { user.username } </div>
            <div className="spacer" />
            <div className="signOut">
              <button onClick={ this.signOut }>Sign out</button>
            </div>
          </nav>
          <div>You don't have access to this page.</div>
        </main>
      );
      }

    return (
      <main>
        <nav>
        <img className="logo" src={ playrLogo } alt="logo"/>
        </nav>
        <div>
          {!csvMauSessionsData &&
            <h1 className="sectionHeaderWhite" style={{ marginTop: '1rem' }}>Generating MAU Sessions csv<Dot>.</Dot><Dot>.</Dot><Dot>.</Dot></h1>
          }
          {csvMauSessionsData &&
            <h1 className="sectionHeaderWhite" style={{ marginTop: '1rem' }}>Done!</h1>
          }
        </div>
        { csvMauSessionsData &&
            <CSVDownload {...csvMauSessionsData} />  
        }
      </main>
    );
  }
}
