import React from 'react';
import moment from 'moment';
import { Table } from 'react-bootstrap';
import './style.css';

const UserDetails = ({ user, className }) => (
  <div className={`UserDetails ${className || ''}`}>
    <Table striped bordered hover variant="dark">
      <thead>
        <tr>
          <th colspan="2">User Info</th>
        </tr>
      </thead>

      <tbody>
        { row('Type:', user.accountType) }
        { row('UserId', user.userId) }
        { row('Email:', user.email) }
        { row('Display:', user.displayName) }
        { row('Name:', user.firstName) }
        { row('Surname:', user.lastName) }
        { row('Gender:', user.gender) }
        { row('Age:', user.age) }
        { row('Dob:', moment(user.dateBirth * 1000).format('LL')) }
      </tbody>
    </Table>
  </div>
);

const row = (col1, col2) => (
  <tr>
    <td className="name">{ col1 }</td>
    <td>{ col2 }</td>
  </tr>
);

export default UserDetails;
