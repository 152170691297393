import React, { Component } from 'react';
import { CSVDownload } from 'react-csv';
import axios from 'axios';
import {
  GET_EMAIL_POD_DATA,
  GET_EMAIL_NO_POD_DATA,
  GET_EMAIL_NO_CAL_DATA,
  GET_EMAIL_RETENTION_INACTIVE_DATA,
  GET_EMAIL_RETENTION_NO_SESSIONS_DATA,
  GET_CSV_POD_DATA,
  GET_CSV_NO_POD_DATA,
  GET_CSV_NO_CAL_DATA,
  GET_CSV_RETENTION_INACTIVE_DATA,
  GET_CSV_RETENTION_NO_SESSIONS_DATA
} from '../../config/api';
import DataPanel from "./components/DataPanel";
import { Auth } from 'aws-amplify';
import _ from 'lodash';
import './style.css';
import analyticsIcon from '../../images/emailLogo.png';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import moment from 'moment';

export default class Emails extends Component {
  _isMounted = false;

  state = {
    statusCode: 200,
    emailPodData: null,
    emailNoPodData: null,
    emailNoCalData: null,
    emailRetentionInactiveData: null,
    emailRetentionNoSessionsData: null,
    csvPodData:null,
    csvNoPodData: null,
    csvNoCalData: null,
    csvInactiveData: null,
    csvRetentionNoSessionsData: null,
    errors: [],
    selectedDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    numOfDays: 7,
  };

  /***
   * Checks to see if the user belongs to the correct group.
   * Ignores this check if in localhost, again calls on backend are secured
   *
   * @returns {boolean|*}
   */
  checkAccess(){
    const { location:{ hostname }} = window;
    if (hostname === "local.pteksys.com" ||
        hostname === "localhost" ||
        hostname === "127.0.0.1" ||
        hostname === "") {
      return true;
    }

    const { user } = this.props;
    const groups = user.signInUserSession.idToken.payload['cognito:groups']
        .map(group => group.toLowerCase());

    return groups.includes('admin') || groups.includes('management');
  }

  componentDidMount() {
    this._isMounted = true;
    if(this.checkAccess()){
      this.loadData();

      this.onResize = this.onResize.bind(this);
      window.addEventListener("resize", this.onResize);
      this.handleSizeChange();
    }
  }

  componentWillUnmount(){
    this._isMounted = false;
    window.removeEventListener("resize", this.onResize)
  }

  onResize(){
    this.handleSizeChange();
  }

  handleSizeChange = _.debounce( () => {}, 500);

  loadRemoteData = async (url, updateStateFn) => {
    try {
      const result = await axios.get(url)
      const { status, data } = result;
      if (status === 200) {
          return updateStateFn(data);
      }
    }
    catch (error) {
      console.log(error);
    };
  }

  loadData = async () => {
    const {
       selectedDate, numOfDays
    } = this.state;

    const toUnixdate = moment(selectedDate).startOf('day').unix();
  
    const promises = [];
    promises.push(this.loadRemoteData(GET_EMAIL_POD_DATA (toUnixdate, numOfDays), (data) => {return {emailPodData: data}}));
    promises.push(this.loadRemoteData(GET_EMAIL_NO_POD_DATA (toUnixdate, numOfDays), (data) => {return {emailNoPodData: data}}));
    promises.push(this.loadRemoteData(GET_EMAIL_NO_CAL_DATA (toUnixdate, numOfDays), (data) => {return {emailNoCalData: data}}));
    promises.push(this.loadRemoteData(GET_EMAIL_RETENTION_INACTIVE_DATA (toUnixdate, numOfDays), (data) => {return {emailRetentionInactiveData: data}}));
    promises.push(this.loadRemoteData(GET_EMAIL_RETENTION_NO_SESSIONS_DATA (toUnixdate, numOfDays), (data) => {return {emailRetentionNoSessionsData: data}}));

    const values = await Promise.all(promises);

    this.setState({
      ...values[0],
      ...values[1],
      ...values[2],
      ...values[3],
      ...values[4],
    });    
  };

  updateCSVExportState =  (mailType, stateValue) => {
    let stateObj = null;
    
    // console.log('Mail Type Update: ' + mailType + JSON.stringify(stateValue));
    switch(mailType) {
      case 1:
        stateObj = { csvPodData: stateValue };
        break;
      case 2:
        stateObj = { csvNoPodData: stateValue };
        break;
      case 3:
        stateObj = { csvNoCalData: stateValue };
        break;
      case 5:
        stateObj = { csvInactiveData: stateValue };
        break;
      case 6:
        stateObj = { csvRetentionNoSessionsData: stateValue };
        break;
      default:
        stateObj = { csvPodData: stateValue };
        break;
    }

    this.setState(stateObj);
  };

  generateReport = async (mailType) => {
    const {
      selectedDate, numOfDays
   } = this.state;

  //  console.log('Mail Type: ' + mailType);
   

   this.updateCSVExportState(mailType, null);

  let csvHeader = "";
  const currentMoment = moment(selectedDate).startOf('day');
  const endMoment = moment(currentMoment).startOf('day').add(numOfDays, 'days');
  let response = [];

   switch(mailType) {
    case 1:
      csvHeader = "totalSetCal";
      response = await axios.get(GET_CSV_POD_DATA (currentMoment.unix(), endMoment.unix(), mailType));
      break;
    case 2:
      csvHeader = "totalPairedPod";
      response = await axios.get(GET_CSV_NO_POD_DATA (currentMoment.unix(), endMoment.unix(), mailType));
      break;
    case 3:
      csvHeader = "totalSetCal";
      response = await axios.get(GET_CSV_NO_CAL_DATA (currentMoment.unix(), endMoment.unix(), mailType));
      break;
    case 5:
      csvHeader = "totalUploaded";
      response = await axios.get(GET_CSV_RETENTION_INACTIVE_DATA (currentMoment.unix(), endMoment.unix(), mailType));
      break;
    case 6:
      csvHeader = "totalUploaded";
      response = await axios.get(GET_CSV_RETENTION_NO_SESSIONS_DATA (currentMoment.unix(), endMoment.unix(), mailType));
      break;
    default:
      csvHeader = "err";
      break;
  }
   
  // console.log(csvHeader);

    const headers = [
      { label: "Date", key: "date" },
      { label: "Sent", key: "totalSentUsers" },
      { label: "Opened", key: "totalOpenedUsers" },
      { label: "Clicked", key: "totalClickedUsers"},
      { label: "Actions", key: `${csvHeader}` }
    ];
    
    this.updateCSVExportState(mailType, {
      data: response.data,
      headers: headers
    });

  }

  signOut = () => {
    Auth
      .signOut()
      .then( () => {
        console.log('Sign out was successful');
    })
      .catch( err =>{
        console.log(err);
      });
  };

  convertObjToGraphArray(obj){
    if(obj === null || obj === undefined) return obj;

    return Object.keys(obj).map(function(key) {
      return { name: key, value: obj[key]};
    });
  }

  render() {
    const {
      emailPodData,
      emailNoPodData,
      emailNoCalData,
      emailRetentionInactiveData,
      emailRetentionNoSessionsData,
    } = this.state;

    const { user } = this.props ;
    if(this.checkAccess() === false){
      return (

        <main>
          <nav>
            <div className="welcome">Welcome { user.username } </div>
            <div className="spacer" />
            <div className="signOut">
              <button onClick={ this.signOut }>Sign out</button>
            </div>
            <div className="logo"><img src={analyticsIcon} alt="logo"/></div>
          </nav>
          <div>You don't have access to this page.</div>
        </main>
      );
    }

    if(!emailNoPodData) { 
      return (
        <div>
          Loading...
        </div>
      );
    }

    const { usersMailOne, usersOpenedMailOne, percentOpenedMailOne, setCal, setCalAfterOpen, percentUsersSetCal, 
      percentUsersSetCalAfterOpen, usersClickedMailOne } = emailPodData || {};
    const { usersMailTwo, usersOpenedMailTwo, percentOpenedMailTwo, pairedAfterOpen, 
      percentPairedAfterOpen, pairedAfterSent, percentPairedAfterSent, usersClickedMailTwo } = emailNoPodData || {};
    const { usersMailThree, usersOpenedMailThree, percentOpenedMailThree, usersSetCal, usersSetCalAfterOpen, 
      percentSetCal, percentSetCalAfterOpen, usersClickedMailThree } = emailNoCalData || {};
    const { usersMailFive, usersOpenedMailFive, percentOpenedMailFive, uploadedSessionAfterOpen, 
      percentUploadedAfterOpen, uploadedIn10DaysPostWeek, percentUploadedWeek, usersClickedMailFive } = emailRetentionInactiveData || {};
    const { usersMailSix, usersOpenedMailSix, percentOpenedMailSix, uploadedSessionAfterOpenEmail, 
      percentUploadedAfterOpenEmail, uploadedSessionIn10DaysPostWeek, percentUploadedSessionWeek, usersClickedMailSix } = emailRetentionNoSessionsData || {};
    const { selectedDate, numOfDays, csvPodData, csvNoPodData, csvNoCalData, csvInactiveData, csvRetentionNoSessionsData } = this.state;

    function addZerosToDate(n){
      if(n <= 9){
        return "0" + n;
      }
      return n
    };

    const fromDate = new Date(selectedDate);
    const startDate = addZerosToDate(fromDate.getMonth() + 1) + "/" + addZerosToDate(fromDate.getDate()) + "/" + fromDate.getFullYear();

    const toDate = +new Date(selectedDate) + (numOfDays * 24 * 60 * 60 * 1000);
    const toDateNew = new Date(toDate);
    const endDate = addZerosToDate(toDateNew.getMonth() + 1) + "/" + addZerosToDate(toDateNew.getDate()) + "/" + toDateNew.getFullYear();

    return (
      <main>
        <nav>
            <img className="logo" src={ analyticsIcon } alt="logo"/>
        </nav>
        <div className="box">
        <p className="sectionHeaderWhite" style={{ marginTop: '1rem'}} > Start Date: &nbsp;</p>
        <DatePicker selected={selectedDate} onChange={date => {
            const newDate = +new Date(date);
            this.setState({selectedDate: newDate }, this.loadData);
          }
          }/>
        </div>
        <div className="box">
        <p className="sectionHeaderWhite" style={{ marginTop: '1rem'}} > Period: {numOfDays} &nbsp;</p>
        <DropdownButton id="dropdown-days" title="Day(s)"  onSelect={days => {
            this.setState({numOfDays: days}, this.loadData);
          }} >
          <Dropdown.Item eventKey="1">1</Dropdown.Item>
          <Dropdown.Item eventKey="7">7</Dropdown.Item>
          <Dropdown.Item eventKey="10">10</Dropdown.Item>
          <Dropdown.Item eventKey="14">14</Dropdown.Item>
          <Dropdown.Item eventKey="30">30</Dropdown.Item>
        </DropdownButton>
        </div>
        <div className="sectionText" >
          <p>(Emails sent between {startDate} and {endDate})</p>
        </div>
        <div style={{ marginBottom: '2rem'}}>
                 
        </div>
        <h1 className="sectionHeaderWhite" style={{ marginTop: '1rem' }}>Welcome Email With Pod</h1>
        <button onClick={() => this.generateReport(1)}>Export</button>
        {csvPodData && <CSVDownload {...csvPodData} target="_blank" />} 
        <hr className="hrLine"/>
        <div className="grid-emails">
          <DataPanel data={usersMailOne} title="Number of specified email sent within selected date range"/>
          <DataPanel data={usersOpenedMailOne} title="Number of users that opened the specified email"/>
          <DataPanel data={usersClickedMailOne} title="Number of users that clicked the specified email"/>
          <DataPanel dataPercent={percentOpenedMailOne} title="Percent of users opened the specified email"/>
          <hr className="hrLineDivider"/>
          <DataPanel data={setCalAfterOpen} title="Number of users that set calender event after opening email"/>
          <DataPanel dataPercent={percentUsersSetCalAfterOpen} title="Percentage of users that set a calender event after opening email"/>
          <DataPanel data={setCal} title="Number of users that set calender event after email sent"/>
          <DataPanel dataPercent={percentUsersSetCal} title="Percentage of users that set a calender event"/>
          </div>
          <h1 className="sectionHeaderWhite" style={{ marginTop: '1rem' }}>Welcome Email With No Pod</h1>
          <button onClick={() => this.generateReport(2)}>Export</button>
          {csvNoPodData && <CSVDownload {...csvNoPodData} target="_blank" />}  
          <hr className="hrLine"/>
          <div className="grid-emails">
          <DataPanel data={usersMailTwo} title="Number of specified email sent within selected date range"/>
          <DataPanel data={usersOpenedMailTwo} title="Number of users that opened the specified email"/>
          <DataPanel data={usersClickedMailTwo} title="Number of users that clicked the specified email "/>
          <DataPanel dataPercent={percentOpenedMailTwo} title="Percent of users opened the specified email"/>
          <hr className="hrLineDivider"/>
          <DataPanel data={pairedAfterOpen} title="Users connected a pod after email opened"/>
          <DataPanel dataPercent={percentPairedAfterOpen} title="Percentage of users set a calender event within date range of email opened"/>
          <DataPanel data={pairedAfterSent} title="Users connected a pod after email sent"/>
          <DataPanel dataPercent={percentPairedAfterSent} title="Percentage of users set a calender event within date range of email sent"/>
          </div>
          <h1 className="sectionHeaderWhite" style={{ marginTop: '1rem' }}>No Calender Set Up Email</h1>
          <button onClick={() => this.generateReport(3)}>Export</button>
          {csvNoCalData && <CSVDownload {...csvNoCalData} target="_blank" />}  
          <hr className="hrLine"/>
          <div className="grid-emails">
          <DataPanel data={usersMailThree} title="Number of specified email sent within selected date range"/>
          <DataPanel data={usersOpenedMailThree} title="Number of users that opened the specified email"/>
          <DataPanel data={usersClickedMailThree} title="Number of users that clicked the specified email"/>
          <DataPanel dataPercent={percentOpenedMailThree} title="Percent of users opened the specified email"/>
          <hr className="hrLineDivider"/>
          <DataPanel data={usersSetCalAfterOpen} title="Number of users that set calender event after opening email"/>
          <DataPanel dataPercent={percentSetCalAfterOpen} title="Percentage of users that set a calender event after opening email"/>
          <DataPanel data={usersSetCal} title="Users set a calender event after email sent"/>
          <DataPanel dataPercent={percentSetCal} title="Percentage of users set a calender event within date range of email sent"/>
          </div>
          <h1 className="sectionHeaderWhite" style={{ marginTop: '1rem' }}>Retention Email - No Upload in 28 Days</h1>
          <button onClick={() => this.generateReport(5)}>Export</button>
          {csvInactiveData && <CSVDownload {...csvInactiveData} target="_blank" />} 
          <hr className="hrLine"/>
          <div className="grid-emails">
          <DataPanel data={usersMailFive} title="Number of specified email sent within selected date range"/>
          <DataPanel data={usersOpenedMailFive} title="Number of users that opened the specified email"/>
          <DataPanel data={usersClickedMailFive} title="Number of users that clicked the specified email"/>
          <DataPanel dataPercent={percentOpenedMailFive} title="Percent of users opened the specified email"/>
          <hr className="hrLineDivider"/>
          <DataPanel data={uploadedSessionAfterOpen} title="Users uploaded a session after within 10 days after opening email"/>
          <DataPanel dataPercent={percentUploadedAfterOpen} title="Percentage of users that uploaded a session within 10 days after opening email"/>
          <DataPanel data={uploadedIn10DaysPostWeek} title="Users uploaded a session within 10 days after email was sent"/>
          <DataPanel dataPercent={percentUploadedWeek} title="Percentage of users that uploaded a session within 10 days after email was sent"/>
          </div>
          <h1 className="sectionHeaderWhite" style={{ marginTop: '1rem' }}>Retention Email - No sessions within 14 days of Connecting a Pod</h1>
          <button onClick={() => this.generateReport(6)}>Export</button>
          {csvRetentionNoSessionsData && <CSVDownload {...csvRetentionNoSessionsData} target="_blank" />}  
          <hr className="hrLine"/>
          <div className="grid-emails">
          <DataPanel data={usersMailSix} title="Number of specified email sent within selected date range"/>
          <DataPanel data={usersOpenedMailSix} title="Number of users that opened the specified email sent within selected date range"/>
          <DataPanel data={usersClickedMailSix} title="Number of users that clicked the specified email"/>
          <DataPanel dataPercent={percentOpenedMailSix} title="Percent of users opened the specified email"/>
          <hr className="hrLineDivider"/>
          <DataPanel data={uploadedSessionAfterOpenEmail} title="Users uploaded a session within 10 days after opening email"/>
          <DataPanel dataPercent={percentUploadedAfterOpenEmail} title="Percentage of users that uploaded a session within 10 days after opening email"/>
          <DataPanel data={uploadedSessionIn10DaysPostWeek} title="Users uploaded a session within 10 days after after email was sent"/>
          <DataPanel dataPercent={percentUploadedSessionWeek} title="Percentage of users set a calender event within 10 days after after email was sent"/>
          </div>
      </main>
    );
  }
}
