import React from 'react';
import './style.css';
import moment from 'moment/moment';
import {Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

const UploadChart = ({ uploads, className }) => {
  const uploadCounts = {};
  const d = moment().utc().subtract(1, 'months').startOf('day');
  const now = moment().utc();

  for (d; d.isBefore(now); d.add(1, 'days')) {
    const label = d.format('D/M/Y');
    uploadCounts[d.valueOf()] = {
      name: label,
      value: 0
    };
  }

  uploads.forEach(upload => {
    const uploadTime = moment(upload.uploadComplete * 1000).utc().startOf('day');
    if (uploadCounts[uploadTime.valueOf()]) {
      ++uploadCounts[uploadTime.valueOf()].value;
    }
  });

  const chartData = Object.values(uploadCounts);

  return (
    <div className={`UploadChart ${className || ''}`}>
      <h5>Uploads (Last 30 Days)</h5>
      <div style={{height: '90%'}}>
        <ResponsiveContainer>
          <BarChart
            data={chartData}
            title="Uploads (Last 30 Days)"
          >
            <XAxis
              hide
              dataKey="name"
            />

            <YAxis dataKey='value' name='Value' allowDecimals={false} />

            <Bar dataKey="value" stackId="a" fill="#82ca9d" barSize={170} />

            <Tooltip
              formatter={(a, b, c) => [a, c.payload.name]} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default UploadChart;
