import React from 'react';
import moment from 'moment';
import { Table } from 'react-bootstrap';
import './style.css';

const PodList = ({ pods, className }) => (
  <div className={`PodList ${className || ''}`}>
    <Table striped bordered hover variant="dark" responsive>
      <thead>
      <tr>
        <th colspan="6">Pods</th>
      </tr>
      <tr>
        <th>Paired</th>
        <th>UUID</th>
        <th>Local Name</th>
        <th>Device Id</th>
        <th>Latest Firmware</th>
        <th>Date Paired</th>
      </tr>
      </thead>

      <tbody>
        { pods && pods.map(renderRow) }
      </tbody>
    </Table>
  </div>
);

const renderRow = (pod) => (
  <tr>
    <td>{ pod.isPaired ? 'Yes' : 'No' }</td>
    <td>{ pod.uuid }</td>
    <td>{ pod.localName }</td>
    <td>{ pod.deviceID }</td>
    <td>{ pod.latest_firmware }</td>
    <td>{ moment(pod.timestampAdded * 1000).format('D/M/Y') }</td>
  </tr>
);

export default PodList;
