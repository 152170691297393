import React from 'react';
import moment from 'moment';
import { Table } from 'react-bootstrap';
import './style.css';
import JsonModal from "../JsonModal";

const PodLogs = ({ podLogs, className }) => (
  <div className={`PodList ${className || ''}`}>
    <Table striped bordered hover variant="dark" responsive>
      <thead>
      <tr>
        <th colspan="6">Pod Logs</th>
      </tr>
      <tr>
        <th>Date</th>
        <th>UUID</th>
        <th>App Version</th>
        <th>Battery</th>
        <th>Last Session</th>
        <th>Last Folder</th>
        <th>Content</th>
      </tr>
      </thead>

      <tbody>
      { podLogs && podLogs.map(renderRow) }
      </tbody>
    </Table>
  </div>
);

const PLAYR_START_TIME = 1483142400;

const renderRow = (podLogs) => {
  let { content } = podLogs;

  if (content && content.snapshots) {
    content.snapshots = content.snapshots.sort((snap1, snap2) => (snap2.sessionNumber - snap1.sessionNumber));
    content.snapshots = content.snapshots.map((snap) => {
      snap.startTime = readablePlayrTime(snap.startTime);
      return snap;
    });
  }

  return (
    <tr>
      <td>{ moment(podLogs.added * 1000).format('D/M/Y h:mm a') }</td>
      <td>{ podLogs.uuid }</td>
      <td>{ podLogs.appVersion }</td>
      <td>{ content.pod.batteryLevel }</td>
      <td>{ content.pod.lastSessionNumber }</td>
      <td>{ content.pod.activeFolderNumber }</td>
      <td>
        <JsonModal
          buttonText="View Content"
          header="Content"
          jsonObject={content} />
      </td>
    </tr>
  )
};

const readablePlayrTime = (playrTime) => {
  return moment(((playrTime / 10) + PLAYR_START_TIME) * 1000).format('D/M/Y h:mm');
};

export default PodLogs;
