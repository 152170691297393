import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export default class JsonModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false,
    };
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  render() {
    const { header, buttonText, jsonObject } = this.props;

    return (
      <>
        <Button
          variant="outline-info"
          size="sm"
          onClick={this.handleShow}>
          { buttonText }
        </Button>

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{ header }</Modal.Title>
          </Modal.Header>
          <Modal.Body><pre>{ this.prettifyJson(jsonObject) }</pre></Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  prettifyJson(object) {
    return JSON.stringify(object, null, 4);
  }
}