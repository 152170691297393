import axios from 'axios';
import {SEARCH_FOR_USER_INFO} from "./endpoints";

/*
  Playr Support API Servers
  -------------------------
  Groups refers to the required Cognito groups to use that server, the user must have this group to run tasks
  against that server, isOnline should be left false and is set on landing from a ping test
 */
export const SERVERS = [
  { url: 'https://90g00ivya4.execute-api.eu-west-1.amazonaws.com/dev/v1', name: 'Ireland', groups: ['support', 'admin', 'management'], isOnline: false },
  { url: 'https://rbmulvd4u1.execute-api.ap-southeast-2.amazonaws.com/dev/v1', name: 'Sydney', groups: ['server-au'], isOnline: false },
  { url: 'https://u8exyoqoaa.execute-api.us-east-1.amazonaws.com/dev/v1', name: 'N. Virginia', groups: ['server-us'], isOnline: false },
  { url: 'http://local.pteksys.com:4000/dev/v1', name: 'Local', groups: ['server-local'], isOnline: false },
];
const SERVER_KEY = 'PLAYR-WEBTOOL-SERVER';
const DEFAULT_SERVER = 'https://90g00ivya4.execute-api.eu-west-1.amazonaws.com/dev/v1';
const BASE_URL = determineServer();

function determineServer() {
  const { location:{ hostname }} = window;
  let server = DEFAULT_SERVER;

  // check if server is already cached in storage
  if (localStorage.getItem(SERVER_KEY)) {
    server = localStorage.getItem(SERVER_KEY);
  } else if (SERVERS.find(s => s.url.indexOf(hostname) > -1)) {
    // check for approximate match to current hostname
    server = SERVERS.find(s => s.url.indexOf(hostname) > -1).url;
  }

  // persist our server selection
  changeServer(server);
  return server;
}

const RESPONSE_TYPE = 'application/json';

export function apiConfig(){
  axios.defaults.baseURL = BASE_URL;
  axios.defaults.headers.common.appVersion = 'PlayrWebSupport';
  axios.defaults.headers.common['Content-Type'] = RESPONSE_TYPE;

  axios.interceptors.request.use(handleRequest);
  axios.interceptors.response.use(handleResponse, handleError);

}

export function changeServer(apiUrl) {
  axios.defaults.baseURL = apiUrl;
  localStorage.setItem(SERVER_KEY, apiUrl);
}

export function currentServer() {
  return SERVERS.find(s => s.url === axios.defaults.baseURL);
}

export function pingServer(serverUrl) {
  if (!serverUrl) {
    return axios.get(SEARCH_FOR_USER_INFO({email: '', name: 'ping-test'}));
  }

  return axios.get(`${serverUrl}/${SEARCH_FOR_USER_INFO({email: '', name: 'ping-test'})}`);
}

export function addAccessTokenHeader(token){
  console.log('Access Token Added!');
  axios.defaults.headers.common.authorization = 'Bearer ' + token;
}

function handleRequest(request) {
  logNetworkTraffic(request);
  return request;
}

function handleResponse(response) {
  logNetworkTraffic(response);
  return response;
}

function handleError(error) {
  logNetworkTraffic(error);
  throw error;
}

function logNetworkTraffic(traffic) {
  /* eslint-disable no-console */
  if (process.env.NODE_ENV === 'development') {
    console.log(traffic);
  }
  /* eslint-enable no-console */
}

export * from './endpoints';
