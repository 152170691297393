import React from 'react';

import Amplify from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import { CognitoConfig } from "./aws-exports";
import './App.css';
import Routes from './routes';
import ServerSelector from "./containers/ServerSelector";
import {addAccessTokenHeader, currentServer} from "./config/api";

Amplify.configure(CognitoConfig);

class App extends  React.Component {
  render(){
    const { authData } = this.props;
    if(authData){
      const { signInUserSession:{ accessToken:{ jwtToken } }} = authData;
      if(jwtToken){
        addAccessTokenHeader(jwtToken);
      }
      else {
        console.log('jwt Token not found');
      }
    }

    return (
      <div className="App">
        <header className="App-header">
          <ServerSelector server={ currentServer() } />
          <Routes user={ authData } />
        </header>
      </div>
    );
  }
}

/**
 * Checks to see if we need to authenticate with AWS Cognito
 * Note the backend calls are still secured, this just handles showing the login screen
 *
 * @param app
 * @returns {*}
 */
function authenticatorCheck(app){
  const { location:{ hostname }} = window;
  if (hostname === "local.pteksys.com" ||
      hostname === "localhost" ||
      hostname === "127.0.0.1" ||
      hostname === "") {
    return app;
  }

  return withAuthenticator(app, false);
}

export default authenticatorCheck(App);
