export const SEARCH_FOR_USER_INFO = params => `user-search/${params.email}/${params.name}`;
export const GET_USER_PODS = params => `user-info/${params.userId}/pods`;
export const GET_USER_PODS_LOGS = params => `user-info/${params.userId}/pods/logs`;
export const GET_USER_UPLOADS = params => `user-info/${params.userId}/uploads`;

export const GET_USER_DATA =  (product) => `reports/users/${product}`;

export const GET_UPLOAD_DATA = (product) => `reports/users/uploads/${product}`;
export const GET_MAP_DATA = (product) => `reports/map/${product}`;
export const GET_SESSION_MONTH_DATA = (product) => `reports/sessions/month/${product}`;
export const GET_RETENTION_DATA = (product) => `reports/churners/${product}`;

export const GET_EMAIL_POD_DATA = (selectedDate, numOfDays) => `reports/emails/pod/${selectedDate}/${numOfDays}`;
export const GET_EMAIL_NO_POD_DATA = (selectedDate, numOfDays) => `reports/emails/no-pod/${selectedDate}/${numOfDays}`;
export const GET_EMAIL_NO_CAL_DATA = (selectedDate, numOfDays) => `reports/emails/no-cal/${selectedDate}/${numOfDays}`;
export const GET_EMAIL_RETENTION_INACTIVE_DATA = (selectedDate, numOfDays) => `reports/emails/inactive/${selectedDate}/${numOfDays}`;
export const GET_EMAIL_RETENTION_NO_SESSIONS_DATA = (selectedDate, numOfDays) => `reports/emails/no-sessions/${selectedDate}/${numOfDays}`;
export const GET_CSV_POD_DATA = (selectedDate, numOfDays, mailType) => `reports/emails/podCsv/${selectedDate}/${numOfDays}/${mailType}`;
export const GET_CSV_NO_POD_DATA = (selectedDate, numOfDays, mailType) => `reports/emails/no-podCsv/${selectedDate}/${numOfDays}/${mailType}`;
export const GET_CSV_NO_CAL_DATA = (selectedDate, numOfDays, mailType) => `reports/emails/no-calCsv/${selectedDate}/${numOfDays}/${mailType}`;
export const GET_CSV_RETENTION_INACTIVE_DATA = (selectedDate, numOfDays, mailType) => `reports/emails/inactiveCsv/${selectedDate}/${numOfDays}/${mailType}`;
export const GET_CSV_RETENTION_NO_SESSIONS_DATA = (selectedDate, numOfDays, mailType) => `reports/emails/no-sessionsCsv/${selectedDate}/${numOfDays}/${mailType}`;

export const GET_CSV_MAU_DATA = 'reports/mau';
export const GET_CSV_MAU_SESSIONS_DATA = 'reports/mau-sessions';


export const GET_APPLE_WATCH_DATA = 'reports/watch/playr';

export const GET_PLAYR_NPS_DATA = `reports/nps/playr`;
export const GET_PLAYR_ACTIVITY_DATA = `reports/playr/activity`;
export const GET_PLAYR_TEAM_DATA = 'reports/teams/playr';
export const GET_PLAYR_POSITION_DATA = `reports/users/positions/playr`;