import React, { Component } from 'react';
import './style.css';
import {
  GET_USER_PODS, GET_USER_PODS_LOGS, GET_USER_UPLOADS,
} from '../../config/api';
import axios from 'axios/index';
import UserDetails from './components/UserDetails';
import {Col, Container, Row} from "react-bootstrap";
import PodList from "./components/PodList";
import UploadList from "./components/UploadList";
import PodLogs from "./components/PodLogs";
import UploadChart from "./components/UploadChart";

export default class UserInfo extends Component {
  state = {
    user: {},
    pods: [],
    podLogs: [],
    uploads: [],
  };

  componentDidMount() {
    // If no user is provided re-route back to home
    const { user } = this.props.location;

    if (!user || !user.userId) {
      const { history } = this.props;
      history.push('/');
    }

    this.loadUserInfo(user);
  }

  async loadUserInfo(user) {
    if (user && user.userId) {
      try {
        const [pods, podLogs, uploads] = await Promise.all([
          axios.get(GET_USER_PODS(user)),
          axios.get(GET_USER_PODS_LOGS(user)),
          axios.get(GET_USER_UPLOADS(user)),
        ]);

        this.setState({
          pods: pods.data,
          podLogs: podLogs.data,
          uploads: uploads.data,
          user,
        });
      }
      catch(err) {
        console.log(err);
      }
    }
  }

  render() {
    const {
      user, pods, uploads, podLogs
    } = this.state;

    if (!user || !pods || !uploads || !podLogs) {
      return null;
    }

    return (
      <Container className="UserInfo">
        <Row className="Row">
          <Col xs={4} className="contain">
            <UserDetails
              user={user}/>
          </Col>
          <Col xs={8} className="contain">
            <PodList
              pods={pods}/>
          </Col>
        </Row>

        <Row className="Row">
          <Col xs={12} className="contain">
            <PodLogs
              podLogs={podLogs}/>
          </Col>
        </Row>

        <Row className="Row">
          <Col xs={12} className="chartContainer">
            <UploadChart
              uploads={uploads}/>
          </Col>
        </Row>

        <Row className="Row marginBottom">
          <Col xs={12} className="contain">
            <UploadList
              uploads={uploads}/>
          </Col>
        </Row>
      </Container>
    );
  }
}
