import React from 'react';
import { Pie, PieChart, Cell, Legend, } from 'recharts';
import './style.css';

const RADIAN = Math.PI / 180;

const CustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x  = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy  + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} fontWeight="bold" dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const ReportPie = ({ title, subTitle, data, dataKey, nameKey, colors }) => {
  if(data) {
    const fontFormatter = (value) => <span className="legendText">{value}</span>;

    return(
      <div className="pie-container">
        <h1>{title}</h1>
        <h2>{subTitle}</h2>
        <div className="chart-align-center">
          <PieChart
            width={275}
            height={250}
          >
            <Pie
              data={data}
              dataKey={dataKey}
              nameKey={nameKey}
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#8884d8"
              labelLine={false}
              label={CustomizedLabel}
            >
              {
                data.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />)
              }
            </Pie>
            <Legend formatter={fontFormatter} />
          </PieChart>
        </div>
      </div>
    );
  } else {
    return(
      <div className="pie-container">
        <h1>{title}</h1>
        <h2>{subTitle}</h2>
        <h2>Loading...</h2>
      </div>
    );
  }
};

export default ReportPie;
