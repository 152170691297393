import React from 'react';
import './style.css';

const DataPanel = ({ data, title }) => {

  return(
    <div className="data-panel">
      <div className="data-panel-parent">
        { data !== null || data !== undefined ? (
          <div>
            <div className="data-panel-value">{ data }</div>
            <div className="data-panel-title">{ title }</div>
          </div>
        ) : (
          <div>
            <div className="data-panel-value">Loading...</div>
            <div className="data-panel-title">{ title }</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DataPanel;
