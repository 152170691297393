import React from 'react';
import moment from 'moment';
import {Button, Table} from 'react-bootstrap';
import './style.css';

const UploadList = ({ uploads, className }) => (
  <div className={`UploadList ${className || ''}`}>
    <Table striped bordered hover variant="dark" responsive>
      <thead>
      <tr>
        <th colspan="15">Uploads</th>
      </tr>
      <tr>
        <th>ID</th>
        <th>Device</th>
        <th>Uploaded</th>
        <th>Folder</th>
        <th>Bytes</th>
        <th>Has Session</th>
        <th>Set Up</th>
        <th>Session Date</th>
        <th>Session Time</th>
        <th>Duration (mins)</th>
        <th>TS</th>
        <th>TD</th>
        <th>SD</th>
        <th>PP</th>
        <th>App Version</th>
        <th>FW</th>
        <th>View Map</th>
      </tr>
      </thead>

      <tbody>
      { uploads && uploads.map(renderRow) }
      </tbody>
    </Table>
  </div>
);

const renderRow = (upload) => (
  <tr>
    <td>{ upload.uploadID }</td>
    <td>{ upload.devices_deviceID }</td>
    <td>{ moment(upload.uploadComplete * 1000).format('D/M/Y') }</td>
    <td>{ upload.sessionTime }</td>
    <td>{ upload.uncompressedSize }</td>
    <td>{ upload.sessionID > 0 ? 'Yes' : 'No' }</td>
    <td>{ upload.hasBeenSetUp === 1 ? 'Yes' : 'No'  }</td>
    <td>{ upload.timestampStart ? moment(upload.timestampStart).format('D/M/Y') : '' }</td>
    <td>{ upload.timestampStart ? moment(upload.timestampStart).format('h:mma') : '' }</td>
    <td>{ toTwoDec(upload.duration / 60) }</td>
    <td>{ toTwoDec(upload.topSpeed) }</td>
    <td>{ toTwoDec(upload.distance) }</td>
    <td>{ toTwoDec(upload.sprintDistance) }</td>
    <td>{ toTwoDec(upload.powerPlays) }</td>
    <td>{ upload.uploaderVersion }</td>
    <td>{ upload.firmware }</td>
    <td>{ goToMapButton(upload) }</td>
  </tr>
);

const toTwoDec = (num) => (Math.round(num * 100) / 100);

const goToMapButton = (upload) => (
  <Button
    variant="outline-info"
    size="sm"
    onClick={() => {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${upload.centreLat},${upload.centreLon}`,
        '_blank'
      );
    }}>
    View Map
  </Button>
);

export default UploadList;
