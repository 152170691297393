import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

class UserMap extends Component {
  static defaultProps = {
    center: {
      lat: 0,
      lng: 0,
    },
    zoom: 1,
    heatMapData: []
  };

  render() {
    if(this.props.heatMapData){
      return (
        <div style={{ height: '95vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyCg8MR3_siR2dv2BEVBcP9ve5iZAEFvmDo' }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            heatmapLibrary={true}
            heatmap={this.props.heatMapData}
          >
          </GoogleMapReact>
        </div>
      );
    }
    return <div/>

  }
}

export default UserMap;
