import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Landing from '../containers/Landing';
import Support from '../containers/Support';
import UserInfo from '../containers/UserInfo';
import NotFound from '../containers/NotFound';
import PlayrDashboard from '../containers/PlayrDashboard';
import Emails from '../containers/Emails';
import MauCsv from '../containers/MauCsv';
import MauSessionsCsv from '../containers/MauSessionsCsv';

export default (user) =>
  <Switch>
    { /** No idea why user is within a user... **/ }
    <Route path="/" exact render={(props) => <Landing {...props} user={user.user}/>} />
    <Route path="/user-info" exact strict render={(props) => <Support {...props} user={user.user}/>} />
    <Route path="/user-info/:userId?" render={(props) => <UserInfo {...props} user={user.user}/>} />
    <Route path="/management-playr/playertek"
           render={(props) => <PlayrDashboard userType={'playertek'} {...props} user={user.user}/>} />
    <Route path="/management-playr" render={(props) => <PlayrDashboard userType={'playr'} {...props} user={user.user}/>} />
    <Route path="/emails" render={(props) => <Emails {...props} user={user.user}/>} />
    <Route path="/mau" render={(props) => <MauCsv {...props} user={user.user}/>} />
    <Route path="/mau-sessions" render={(props) => <MauSessionsCsv {...props} user={user.user}/>} />

    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;
