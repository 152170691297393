import React from 'react';
import {Bar, BarChart, Label, ResponsiveContainer, XAxis, YAxis, Tooltip, CartesianGrid, Cell} from 'recharts';
import '../../style.css';

const goodColor = 'rgb(60, 188, 152)';
const badColor =  'rgb(188,10,0)';
const toolTip = ({ active, payload, label }) => {
  if (active) {
    const { payload:{current, previous} } = payload[0];

    const actual = Math.round((current.value - previous.value)/ previous.value  * 1e4 ) / 1e2;
    const color = actual >= 0 ? goodColor : badColor;
    return (
      <div className="graph-tooltip">
        <p>Year on Year</p>
        <h1 style={{ color: color}}>{actual}%</h1>
        <p>Week {label}</p>
        <h3>{current.value} ({current.year})</h3>
        <h3>{previous.value} ({previous.year})</h3>
      </div>
    );
  }

  return null;
};

export const YearOnYear = ({ title, subTitle, xLabel, yLabel, data, dataKey, nameKey, interval }) => {
  return(
    <div className="graph" style={{ marginTop: 16 }}>
      <h1>{title}</h1>
      <h2>{subTitle}</h2>
      <ResponsiveContainer
        width="100%"
        height={300}>
        <BarChart
          data={data}
          margin={{ top: 15, right: 30, left: 20, bottom: 5 }}>
          <XAxis
            dataKey={nameKey}
            type="category"
            interval={interval}
            tick={{ fontSize: 12, fill: 'white' }}>
            <Label
              value={xLabel}
              offset={-2.5}
              position="insideBottom"
              style={{ textAnchor: 'middle', fontSize: '0.85rem', fill: 'rgba(255, 255, 255, 0.87)' }}
            />
          </XAxis>
          <YAxis
            dataKey={dataKey}
            name='Value'
            allowDecimals={false}
            tick={{ fontSize: 12, fill: 'white' }}
          >
            <Label
              value={yLabel}
              angle={-90}
              offset={0}
              position="insideLeft"
              style={{ textAnchor: 'middle', fontSize: '0.85rem', fill: 'rgba(255, 255, 255, 0.87)' }}
            />
          </YAxis>

          <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
          <Tooltip content={toolTip} cursor={false} />
          <Bar dataKey="value" stackId="a" barSize={10}>
            {
              data.map((entry, index) => (
                <Cell key={`c${index}`} fill={entry.value >= 0 ? goodColor : badColor }/>
              ))
            }
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};


