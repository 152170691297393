import React, { Component } from 'react';
import {Button, FormGroup, FormControl, FormLabel, ListGroup} from 'react-bootstrap';
import axios from 'axios';
import { SEARCH_FOR_USER_INFO, pingServer } from '../../config/api';
import './style.css';

export default class Support extends Component {
  state = {
    email: '',
    name: '',
    searchResults: [],
  };

  componentDidMount() {
    pingServer();
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { email, name } = this.state;

    const searchUrl = SEARCH_FOR_USER_INFO({ email, name });
    const { data } = await axios.get(searchUrl);

    if (data && data.length) {
      if (data.length === 1) {
        const user = data[0];
        this.goToUserInfo(user);
      }
      else {
        this.setState({ searchResults: data });
      }
    }
  };

  goToUserInfo = (user) => {
    const { history } = this.props;

    history.push({
      pathname: '/user-info/',
      user
    });
  };

  validateForm = () => {
    return this.state.email.length
      || this.state.name.length;
  };

  render() {
    return (
      <div className="Home">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="email" bssize="large">
            <FormLabel>Email</FormLabel>
            <FormControl
              autoFocus
              value={this.state.email}
              onChange={this.handleChange}
            />
          </FormGroup>

          <FormGroup controlId="name" bssize="large">
            <FormLabel>Name</FormLabel>
            <FormControl
              value={this.state.name}
              onChange={this.handleChange}
            />
          </FormGroup>

          <Button
            block
            bssize="large"
            disabled={!this.validateForm()}
            type="submit"
          >
            Search
          </Button>
        </form>

        {this.state.searchResults.length <= 1 ? null :
          <ListGroup className="searchResults">
            {this.state.searchResults.map(this.renderUserResult)}
          </ListGroup>
        }
      </div>
    );
  }

  renderUserResult = (user) => {
    return (
      <ListGroup.Item
        action
        variant="dark"
        onClick={() => this.goToUserInfo(user)}>
        { `${user.email} | ${user.firstName} | ${user.lastName}` }
      </ListGroup.Item>
    );
  }
}
