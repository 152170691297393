import React from 'react';
import './style.css';


const renderRow = (rowData , index, max) => {
  const width = rowData.value / max;
  return (
    <div className="dataRow" key={`row-${index}`}>
      <div className="rowLabel">{rowData.position}</div>
      <div className="graphParent">
        <div className="rowBarGraph" style={{ width:`${width * 100}%`, opacity:`${width}` }}>&nbsp;</div>
        <div className="rowValue"> {rowData.value}%</div>
      </div>
    </div>
  );
};

const DataPanel = ({ data, title }) => {
  data.sort((lhs, rhs) => rhs.value - lhs.value);
  const maxValue = data.length > 0 ? data[0].value : 1;

  return(
    <div className="data-table">
      <h1>Positions</h1>
      { data ? (
        data.map((row, index) => renderRow(row, index, maxValue))
      ) : (
        <div>
          <div className="data-panel-value">Loading...</div>
          <div className="data-panel-title">{ title }</div>
        </div>
      )}
    </div>
  );
};

export default DataPanel;
