import React, { Component } from 'react';
import {SERVERS, pingServer, changeServer, currentServer} from '../../config/api';
import './style.css';

export default class Landing extends Component {
    state = {
        groups: [],
        servers: [],
        tasks: [
            { url: '/user-info', name: 'User Search', subtitle: 'email or name', groups: ['support'] },
            { url: '/mau', name: 'Active Users', subtitle: 'monthly', groups: ['admin', 'management'] },
            { url: '/mau-sessions', name: 'Active Users', subtitle: 'sessions', groups: ['admin', 'management'] },
            { url: '/emails', name: 'Email', subtitle: 'usage stats', groups: ['admin', 'management'] },
            { url: '/management-playr', name: 'Playr', subtitle: 'usage stats', groups: ['admin'] },
            { url: '/management-playr/playertek', name: 'Playertek', subtitle: 'usage stats', groups: ['admin'] },
        ]
    };

    componentDidMount() {
        const { user } = this.props;
        const groups = user && user.signInUserSession
            ? user.signInUserSession.idToken.payload['cognito:groups'].map(group => group.toLowerCase())
            : ['admin', 'support', 'management', 'server-local'];

        this.setState({...this.state, groups: groups});

        SERVERS
            .filter(server => server.groups.some(v => groups.includes(v)))
            .forEach(server => {
                pingServer(server.url)
                    .then(_ => {
                        let servers = this.state.servers;
                        server.isOnline = true;
                        servers.push(server);
                        this.setState({ ...this.state, servers: servers });
                    })
                    .catch(_ => {
                        let servers = this.state.servers;
                        server.isOnline = false;
                        servers.push(server);
                        this.setState({ ...this.state, servers: servers });
                    });
            });
    }

    serverSelection = (server) => {
        this.setState({ ...this.state, server: server.url });

        try {
            changeServer(server.url);
        } catch (ex) {
            console.error(`Server could not be changed to ${server.url}.`);
        }
    };

    redirect = (url) => {
        const { history } = this.props;
        history.push({
            pathname: url
        });
    }

    renderTasks() {
        const { tasks, groups, servers } = this.state;
        const approvedTasks = tasks.filter(task => task.groups.some(v => groups.includes(v)));

        // if only single task, on single server redirect instantly
        if (servers.length === 1 && approvedTasks.length === 1) {
            changeServer(servers[0].url);
            this.redirect(approvedTasks[0].url);
        }

        return (
            <div>
                <h2>Task Selection</h2>
                <div className="nav-grid">
                    {approvedTasks
                        .map(task => (
                            <div key={`task-${task.name}_${task.subtitle}`} className="nav-grid-item server" onClick={e => this.redirect(task.url)}>
                                <div>{task.name}</div>
                                <span>{task.subtitle}</span>
                            </div>
                        ))}
                </div>
            </div>
        )
    }

    render() {
        const { servers } = this.state;
        const server = currentServer();
        const connectedServer = server ? servers.find(s => s.url === server.url) : null;

        return (
            <article>
                <h2>Server Selection</h2>
                <div className="nav-grid">
                    {servers
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(server => (
                            <div key={`server-grid-${server.name}`} className={`nav-grid-item server ${connectedServer === server ? 'current' : 'not-current'}`}
                                 onClick={e => this.serverSelection(server)}>
                                <div>{server.name}</div>
                                <span className={`${server.isOnline ? 'online' : 'offline'}`}>{server.isOnline ? 'online' : 'offline'}</span>
                            </div>
                    ))}
                </div>

                {connectedServer && connectedServer.isOnline && this.renderTasks()}
            </article>
        );
    }
}