import React, { Component } from 'react';
import axios from 'axios';
import {
  GET_USER_DATA,
  GET_UPLOAD_DATA,
  GET_MAP_DATA,
  GET_SESSION_MONTH_DATA,
  GET_PLAYR_NPS_DATA,
  GET_PLAYR_ACTIVITY_DATA,
  GET_APPLE_WATCH_DATA,
  GET_PLAYR_TEAM_DATA,
  GET_PLAYR_POSITION_DATA, 
  GET_RETENTION_DATA
} from '../../config/api';
import DataPanel from "./components/DataPanel";
import { Auth } from 'aws-amplify';
import _ from 'lodash';
import './style.css';
import playrLogo from '../../images/playr-log.png';
import playrtekLogo from '../../images/playertek-logo.png';

import ReportPie from "./components/ReportPie";
import DataTable from './components/DataTable';
import {ReportLineGraph} from "./components/ReportLineGraph";
import {ReportBarGraph} from "./components/ReportBarGraph";
import {YearOnYear} from "./components/YearOnYearGraph/YearOnYearGraph";
import UserMap from "./components/UserMap";

const CustomAgeTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="graph-tooltip">
        <p>Age: {label}</p>
        <p>User Base: {payload[0].value.toFixed(2)}%</p>
      </div>
    );
  }

  return null;
};

const RetentionToolTip = ({ active, payload, label }) => {
  if (active && payload && payload.length >=2) {
    return (
      <div className="graph-tooltip">
        <h2>Week {label}</h2>
        <p>PLAYR: {payload[0].value}%</p>
        <p>PLAYERTEK: {payload[1].value}%</p>
      </div>
    );
  }

  return null;
};

const MauToolTip = ({ active, payload, label }) => {
  if (active && payload && payload.length >=2) {
    return (
      <div className="graph-tooltip">
        <p>Week {label}</p>
        <p>Active users over total user base: {payload[0].value}%</p>
        <p>Active Users over users active in the last 90 days: {payload[1].value}%</p>
      </div>
    );
  }

  return null;
};

const GenerateWeekToopTip = message => {
  return ({ active, payload }) => {
    if (active && payload) {
      const { payload:{weekNumber, year, value} } = payload[0];
      return (
        <div className="graph-tooltip">
          <p>Week {weekNumber} ({year})</p>
          <p>{value} {message}</p>
        </div>
      );
    }

    return null;
  }
};


const ChurnToolTip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="graph-tooltip">
        <p>Week {label}</p>
        <p>{payload[0].value} users have not uploaded in the previous 90 days</p>
        <p>{payload[1].value} users have not uploaded in the previous 90 days (but returned)</p>
      </div>
    );
  }

  return null;
};

const COLORS = ['#e31c79', '#6c218a', '#FFBB28', '#FF8042', '#3CBC98'];

export default class PlayrDashboard extends Component {
  _isMounted = false;

  state = {
    statusCode: 200,
    primaryUserData: null,
    secondaryUserData: null,
    npsData: null,
    sessionMonthData: null,
    ageChartInterval: 0,
    registrationChartInterval: 0,
    activityData: null,
    positionData:null,
    appleWatchData: null,
    uploadData: null,
    teamData: null,
    mapData:null,
    retentionData:null,
    errors: [],
  };

  /***
   * Checks to see if the user belongs to the correct group.
   * Ignores this check if in localhost, again calls on backend are secured
   *
   * @returns {boolean|*}
   */
  checkAccess(){
    const { location:{ hostname }} = window;
    if (hostname === "local.pteksys.com" ||
        hostname === "localhost" ||
        hostname === "127.0.0.1" ||
        hostname === "") {
      return true;
    }

    const { user } = this.props;
    const groups = user.signInUserSession.idToken.payload['cognito:groups']
        .map(group => group.toLowerCase());

    return groups.includes('admin') || groups.includes('management');
  }

  componentDidMount() {
    this._isMounted = true;
    if(this.checkAccess()){
      this.loadData();
      this.onResize = this.onResize.bind(this);
      window.addEventListener("resize", this.onResize);
      this.handleSizeChange();

    }
  }

  componentWillUnmount(){
    this._isMounted = false;
    window.removeEventListener("resize", this.onResize)
  }

  onResize(){
    this.handleSizeChange();
  }

  handleSizeChange = _.debounce( () => {
    let ageChartInterval, registrationChartInterval;

    if(window.innerWidth < 800){
      ageChartInterval = 1;
      registrationChartInterval = 2;
    }
    else if(window.innerWidth < 1200){
      ageChartInterval = 0;
      registrationChartInterval = 1;
    }
    else {
      ageChartInterval = 0;
      registrationChartInterval = 0;
    }

    this.setState(
      {
        ageChartInterval,
        registrationChartInterval
      }
    );
  }, 500);

  loadRemoteData(url, updateStateFn) {
    axios
      .get(url)
      .then(result => {
        const { status, data } = result;
        if(status === 200) {
          if(this._isMounted){
            this.setState(updateStateFn(data));
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  loadData(){
    const { userType } = this.props;
    const secondaryDataSource = userType === 'playertek' ? GET_USER_DATA('playr') : GET_USER_DATA('playertek');

    this.loadRemoteData(GET_USER_DATA(userType), (data) =>  { return { primaryUserData: data }});
    this.loadRemoteData(secondaryDataSource, (data) =>  { return { secondaryUserData: data }});
    this.loadRemoteData(GET_SESSION_MONTH_DATA(userType), (data) => { return { sessionMonthData: data }});
    this.loadRemoteData(GET_UPLOAD_DATA(userType), (data) =>  { return { uploadData: data }});
    this.loadRemoteData(GET_MAP_DATA(userType), (data) =>  { return { mapData: data }});
    this.loadRemoteData(GET_RETENTION_DATA(userType), data => ({ retentionData: data }));

    if(userType !== 'playertek') {
      this.loadRemoteData(GET_PLAYR_NPS_DATA, (data) =>  { return { npsData: data }});
      this.loadRemoteData(GET_PLAYR_ACTIVITY_DATA, (data) => {return { activityData: data }});
      this.loadRemoteData(GET_APPLE_WATCH_DATA, (data) => {return { appleWatchData: data }});
      this.loadRemoteData(GET_PLAYR_TEAM_DATA, (data) => {return { teamData: data }});
      this.loadRemoteData(GET_PLAYR_POSITION_DATA, (data) => {return { positionData: data }});
    }
  }

  signOut = () => {
    Auth
      .signOut()
      .then( () => {
        console.log('Sign out was successful');
    })
      .catch( err =>{
        console.log(err);
      });
  };

  /***
   *
   * Parses the first pair data generating the full registration data and year on year
   *
   */
  parseFirstPairData(data){
    const fullRegData = [];
    const yearOnYear = [];
    let previousYear;
    if(data){
      for(let year of Object.keys(data)){
        for(let weekNumber of Object.keys(data[year])){
          const yearValue = data[year][weekNumber];

          fullRegData.push({
            weekNumber,
            year,
            value: yearValue,
          });

          if(previousYear) {
            const previousYearValue = data[previousYear][weekNumber];
            const percentage = Math.round((yearValue - previousYearValue) / previousYearValue * 100.0 * 1e2) / 1e2;
            if (previousYearValue) {
              yearOnYear.push({
                name: weekNumber,
                value: Math.min(percentage, 500),
                current: { year: year, value: yearValue },
                previous: { year: previousYear, value: previousYearValue}
              });
            }
          }
        }

        previousYear = year;
      }
    }

    return { fullRegData, yearOnYear };
  }

  convertObjToGraphArray(obj){
    if(obj === null || obj === undefined) return obj;

    return Object.keys(obj).map(function(key) {
      return { name: key, value: obj[key]};
    });
  }

  render() {
    const {
      primaryUserData,
      npsData,
      ageChartInterval,
      registrationChartInterval,
      sessionMonthData,
      secondaryUserData,
      activityData,
      positionData,
      appleWatchData,
      uploadData,
      teamData,
      mapData,
      retentionData: sixMonthRetention,
    } = this.state;

    const { user } = this.props ;
    if(this.checkAccess() === false){
      return (

        <main>
          <nav>
            <div className="welcome">Welcome { user.username } </div>
            <div className="spacer" />
            <div className="signOut">
              <button onClick={ this.signOut }>Sign out</button>
            </div>
            <div className="logo"><img src={playrLogo} alt="logo"/></div>
          </nav>
          <div>You don't have access to this page.</div>
        </main>
      );
    }

    if(!primaryUserData) {
      return (
        <div>
          Loading...
        </div>
      );
    }

    const { userInfo, ageBreakdown, genderBreakdown, firstPairByWeek, retentionData } = primaryUserData || {};
    const { total, mau, newRegisters,  newRegistersWithPod, currentlyPaired, previouslyPaired} = userInfo || {};
    const { withPod:ageBreakdownObj } = ageBreakdown || {};
    const { withPod:genderBreakdownObj } = genderBreakdown || {};
    const { nps, breakdown:npsBreakdown } = npsData || {};

    const { retentionData:secondaryRetention } = secondaryUserData || {};

    // Prepare data for charts
    let totalNum = 0;

    const ageBreakdownData = this.convertObjToGraphArray(ageBreakdownObj);
    if(ageBreakdownObj){
      for (let i = 1; i < ageBreakdownData.length; i++) {
        totalNum += ageBreakdownData[i].value;
      }
      ageBreakdownData.map( ele => ele.value = (ele.value / totalNum) * 100.0);
    }

    const genderBreakdownData = this.convertObjToGraphArray(genderBreakdownObj);

    const { fullRegData, yearOnYear } = this.parseFirstPairData(firstPairByWeek);
    const { userType } = this.props;
    if(retentionData && secondaryRetention){
      retentionData.map( (ele,index) => {
        if(userType === 'playr') {
          ele.playr = retentionData[index].value;
          ele.playertek = secondaryRetention[index].value;
        } else {
          ele.playr = secondaryRetention[index].value;
          ele.playertek = retentionData[index].value;
        }
        return ele;
      });

    }

    const totalRegData = [];
    if(fullRegData) {
      let runningTotal = 0;
      fullRegData.map(ele => {
        const { weekNumber, year, value } = ele;
        runningTotal += value;
        totalRegData.push({ weekNumber, year, value: runningTotal});
        return runningTotal;
      });
    }

    const { mauTotal, churners } = activityData || {};
    const isPlayr = userType !== 'playertek';

    const {
      awNumUsers,
      awNumTrialUsers,
      awNumActiveSub,
      awSubscriptionBreakdown,
      awGenderBreakdown,
      awConversion,
      awDoubleDipper,
      awTotalSessions,
      awAutoRenew,
      awUploadsByWeek,
    } = appleWatchData || {};

    const awGenderBreakdownGraphData = this.convertObjToGraphArray(awGenderBreakdown);
    const awSubscriptionBreakdownGraphData = this.convertObjToGraphArray(awSubscriptionBreakdown);


    let os, avgSessionsUploaded, registrationWeekData;
    if(uploadData) {
      os = uploadData.os;
      avgSessionsUploaded =  uploadData.avgSessionsUploaded;
      registrationWeekData = uploadData.regData;
    }

    const {
      teamsTotal,
      teamsPopulated,
      teamsMostPlayers,
      teamsPrivate,
      teamsMediaSet,
      teamNumUsers,
      teamNumUploads,
      teamNumUsersActive,
      teamSizes,
      teamPods,
      teamsCreated,
    } = teamData || {};

    return (
      <main>
        <nav>
            <img className="logo" src={ isPlayr ? playrLogo : playrtekLogo} alt="logo"/>
        </nav>
        <div className="grid">
          <DataPanel data={total} title="Total Registrations"/>
          <DataPanel data={currentlyPaired} title="Users with a pod currently paired"/>
          {
            isPlayr && os &&
              <ReportPie
                title="Session Uploads By OS"
                data={os}
                dataKey="count"
                nameKey="os"
                colors={COLORS}
              />
          }
          <ReportPie
            title="Breakdown By Gender"
            data={genderBreakdownData}
            dataKey="value"
            nameKey="name"
            colors={COLORS}
          />
          {
            positionData &&
            <DataTable
              title="Position"
              data={positionData}
              dataKey="value"
              nameKey="position"
              colors={COLORS}
            />
          }
          <DataPanel data={previouslyPaired} title="Users had a pod but no pod currently paired"/>
          <DataPanel data={newRegisters} title="Registered in last 30 days"/>
          <DataPanel data={newRegistersWithPod} title="of which also paired a pod"/>
          <DataPanel data={mau} title="Monthly Active Users"/>

          {
            sessionMonthData && sessionMonthData.sessionBreakdown && sessionMonthData.gameBreakdown.length &&
            <>
              <DataPanel data={ sessionMonthData ? sessionMonthData.total : null } title="Sessions in the last 30 days"/>
              <ReportPie
                title="Games Vs Training"
                subTitle="in last 30 days"
                data={sessionMonthData.sessionBreakdown || []}
                dataKey="value"
                nameKey="name"
                colors={COLORS}
              />
              <ReportPie
                title="Game Breakdown"
                subTitle="in last 30 days"
                data={sessionMonthData.gameBreakdown || []}
                dataKey="value"
                nameKey="name"
                colors={COLORS}
              />
            </>
          }
          </div>
          <ReportBarGraph
            title="Age Breakdown"
            data={ageBreakdownData}
            dataKey="value"
            nameKey="name"
            interval={ageChartInterval}
            colors={COLORS}
            xLabel="Age"
            yLabel="Percentage of users"
            toolTip={CustomAgeTooltip}
          />
          <ReportBarGraph
            title="Registrations by Week"
            subTitle="All users"
            data={registrationWeekData}
            dataKey="value"
            nameKey="weekNumber"
            interval={registrationChartInterval}
            colors={COLORS}
            xLabel="Week Number"
            yLabel="Number of Registrations"
            toolTip={GenerateWeekToopTip('users registered')}
          />
          <ReportBarGraph
            title="Registrations by Week"
            subTitle="Based on first pod pair"
            data={fullRegData}
            dataKey="value"
            nameKey="weekNumber"
            interval={registrationChartInterval}
            colors={COLORS}
            xLabel="Week Number"
            yLabel="Number of Registrations"
            toolTip={GenerateWeekToopTip('users registered with a pod')}
          />
          <ReportLineGraph
            title="Total users with pod"
            data={totalRegData}
            dataKeys={["value"]}
            nameKey="weekNumber"
            interval={registrationChartInterval}
            colors={COLORS}
            xLabel="Week Number"
            yLabel="Users"
            toolTip={GenerateWeekToopTip('total users')}
          />
          <YearOnYear
            title="Registrations Year on Year"
            subTitle="Limited to 500% on graph"
            data={yearOnYear}
            dataKey="value"
            nameKey="name"
            interval={registrationChartInterval}
            xLabel="Week Number"
            yLabel="Percentage against Previous Year"
          />
          {
            mapData &&
              <>
                <h1 className="sectionHeader" style={{ marginTop: '1rem' }}>Users by Location</h1>
                <UserMap heatMapData={mapData} />
              </>
          }
          {
            isPlayr &&
              <>
                <h1 className="sectionHeader" style={{ marginTop: '1rem' }}>PLAYR NPS</h1>
                <div className="grid">
                  <DataPanel data={nps} title="Net Promoter Score"/>
                  <ReportPie
                    title="NPS Breakdown"
                    data={npsBreakdown}
                    dataKey="value"
                    nameKey="name"
                    colors={COLORS}
                    />
                </div>
              </>
          }
          {
            isPlayr && teamData &&
              <>
                <h1 className="sectionHeader">PLAYR Team Users</h1>
                <div className="grid">
                  <DataPanel data={teamsTotal} title="Teams Created"/>

                  <DataPanel data={teamsPopulated} title="Teams with more than 1 member"/>
                  <ReportPie
                    title="Team Size"
                    data={teamSizes}
                    dataKey="value"
                    nameKey="name"
                    colors={COLORS}
                  />
                  <DataPanel data={teamsMostPlayers} title="Largest Team Size"/>
                  <DataPanel data={teamsPrivate} title="Number of teams set to private"/>
                  <DataPanel data={teamsMediaSet} title="Number of teams with a profile image set"/>
                  <DataPanel data={teamNumUploads} title="Number of uploads by users in a team"/>
                  <DataPanel data={teamNumUsers} title="Number of users in a team"/>
                  <ReportPie
                    title="Team Users"
                    subTitle="Current pod state"
                    data={teamPods}
                    dataKey="value"
                    nameKey="name"
                    colors={COLORS}
                  />
                  <DataPanel data={teamNumUsersActive} title="Number of active users in the last 30 days in a team"/>
                </div>
                <ReportBarGraph
                  title="Teams Created"
                  subTitle="by week"
                  data={teamsCreated}
                  dataKeys={"value"}
                  labels={'PLAYR'}
                  nameKey="weekNumber"
                  colors={COLORS}
                  xLabel="Weeks"
                  yLabel="Teams created"
                  toolTip={GenerateWeekToopTip('teams created')}
                />
              </>
          }
          {
            isPlayr &&
            <>
              <h1 className="sectionHeader">Apple Watch</h1>
              <div className="grid">
                <DataPanel data={awNumUsers} title="Apple Watch - Total Users (all time)"/>
                <DataPanel data={awNumActiveSub} title="Apple Watch - Active Subscriptions"/>
                <DataPanel data={awAutoRenew} title="Apple Watch - Sub set to auto renew"/>
                <DataPanel data={awNumTrialUsers} title="Apple Watch - Active Trials"/>
                <DataPanel data={awDoubleDipper} title="Apple Watch - Double Dipper"/>
                <DataPanel data={awConversion} title="Apple Watch - Converted to Pod"/>
                <DataPanel data={awTotalSessions} title="Apple Watch - Sessions"/>
                <ReportPie
                  title="Apple Watch Gender Breakdown"
                  data={awGenderBreakdownGraphData}
                  dataKey="value"
                  nameKey="name"
                  colors={COLORS}
                />
                <ReportPie
                  title="Apple Watch Sub Breakdown"
                  data={awSubscriptionBreakdownGraphData}
                  dataKey="value"
                  nameKey="name"
                  colors={COLORS}
                />
              </div>
              <ReportBarGraph
                title="Apple Watch Uploads"
                subTitle="By Week"
                data={awUploadsByWeek}
                dataKey="value"
                nameKey="weekNumber"
                interval={registrationChartInterval}
                colors={COLORS}
                xLabel="Week Number"
                yLabel="Uploads"
                toolTip={GenerateWeekToopTip('uploads')}
              />
            </>
          }
        <h1 className="sectionHeader">Engagement</h1>
        <ReportLineGraph
          title="12 Week Retention"
          data={retentionData}
          dataKeys={["playr", "playertek"]}
          labels={['PLAYR', 'PLAYERTEK']}
          nameKey="name"
          colors={COLORS}
          xLabel="Weeks Active"
          yLabel="Percentage of Users"
          toolTip={RetentionToolTip}
        />
        {
          avgSessionsUploaded &&
          <ReportLineGraph
            title="Average Uploads Per Week"
            data={avgSessionsUploaded}
            dataKeys={["value"]}
            labels={['PLAYR']}
            nameKey="weekNumber"
            colors={COLORS}
            xLabel="Weeks"
            yLabel="Uploads Per Week"
            toolTip={GenerateWeekToopTip('sessions uploaded per active user')}
          />
        }
        <ReportLineGraph
          title="Percentage Active Users"
          subTitle="Active in the last 6 months"
          data={sixMonthRetention}
          dataKeys={["value"]}
          nameKey="weekNumber"
          colors={COLORS}
          xLabel="Weeks Active"
          yLabel="Percentage of Users"
          toolTip={GenerateWeekToopTip('% users who have uploaded in the last 6 months')}
        />
        {
          isPlayr && (
            activityData ? (
              <div>
                <ReportLineGraph
                  title="Percentage MAU By Week "
                  data={mauTotal}
                  dataKeys={["mau", "mauChurned"]}
                  labels={["MAU over All Users", "MAU over active Users"]}
                  nameKey="weekNumber"
                  interval={registrationChartInterval}
                  colors={COLORS}
                  xLabel="Week Number"
                  yLabel="Percentage of User Base"
                  toolTip={MauToolTip}
                />
                <ReportLineGraph
                  title="Number of Churners"
                  subTitle="Inactive for 90 days or more"
                  data={churners}
                  dataKeys={["lifetime", "temporaryChurners"]}
                  nameKey="weekNumber"
                  interval={registrationChartInterval}
                  colors={COLORS}
                  xLabel="Week Number"
                  yLabel="Number of Churned Accounts"
                  labels={["Number of users not uploaded in 90 days or more", "Number of inactive users but returned"]}
                  toolTip={ChurnToolTip}
                />
              </div>
            ) : ( <span className='loader'>Still loading ...</span>)
          )
        }
      </main>
    );
  }
}
